<template>
  <a-modal title="点赞基数" :width="500" :visible="visible" :confirmLoading="confirmLoading" :destroyOnClose="true"
    @ok="handleSubmit" @cancel="handleCancel">
    <a-spin :spinning="confirmLoading">
      <a-row :gutter="24">
        <a-col :md="24" :sm="24">
          <a-form :form="form">
            <!-- 礼物名称 -->
            <a-form-item label="点赞基数" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-input-number placeholder="请输入" style="width: 100%" :min="0"
                v-decorator="['likeBasicNum', { rules: [{ required: true, message: '请输入！' }] }]" />
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
    </a-spin>
  </a-modal>
</template>

<script>
import { videoListDetail, editVideo } from '@/api/modular/mallLiving/videoList'

export default {
  data() {
    return {
      visible: false, //modal框显示状态
      confirmLoading: false,
      details: {},
      form: this.$form.createForm(this),
      showBigImg: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      editId: ''
    }
  },
  methods: {
    //初始化方法
    show(record) {
      this.visible = true
      this.confirmLoading = true
      this.editId = record.id
      //获取详情数据
      setTimeout(() => {
        videoListDetail({ id: record.id }).then((res) => {
          if (res.success) {
            this.confirmLoading = false
            this.form.setFieldsValue({
              likeBasicNum: details.likeBasicNum
            })
          }else{
            this.confirmLoading = false
          }
        }).catch(err =>{
          this.confirmLoading = false
        })
      }, 100)
    },
    handleSubmit() {
      this.confirmLoading = true
      this.form.validateFields((errors, values) => {
        if (!errors) {
          // 调取编辑接口
          editVideo({ id: this.editId, likeBasicNum: values.likeBasicNum }).then((res) => {
            if (res.success) {
              this.$message.success("点赞基础编辑成功！")
              this.$emit("ok", values)
              this.confirmLoading = false
              this.handleCancel()
            } else {
              setTimeout(() => {
                this.confirmLoading = false
              }, 600)
            }
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel() {
      this.visible = false //关闭对话框
      this.confirmLoading = false
      this.form.resetFields() //重置表单
    },
  }
}
</script>